import React, { Component } from "react";
import Footer from "../../../component/shared/Footer";
import Video from "../../Services/WebDesigning/Video";
import Content from "../../Services/WebDesigning/Content";
import PageHelmet from "../../../component/shared/PageHelmet";
import Header from "../../../component/commonheader/Header";
import HeadingImages from "../../../component/HeadingImage";
const heading = "Boost Efficiency with ERP Software Services in Dharuhera";
export default class ERP extends Component {
  render() {
    return (
      <>
        <PageHelmet
          pageTitle="H&M Innovance LLP - Best Web Designing in dharuhera,india"
          description="H&M INNOVANCE LLP is the best web designing Company in Dharuhera, India Our expert designers provide exclusive website development services in India"
          keywords="About US Website Designing, Digital Agency, Digital Marketing, Internet Marketing"
          canonical="https://www.hminnovance.com//product/erp"
        />
        <Header />
        {/* <!-- web design section start --> */}
        <HeadingImages
          image="/assets/image/webDesign.webp"
          title="Enterprise resource planning "
          heading={heading.toUpperCase()}
          detail={
            "Enhance productivity and streamline processes in Dharuheravwith our ERP software services."
          }
          previous="Home"
          next="Blog"
          para={"blog"}
        />
        {/* <!-- web design section end --> */}
        {/* <Content /> */}
        {/* <Video /> */}
        <Footer />
      </>
    );
  }
}
