import React from "react";
import { Helmet } from "react-helmet";

const PageHelmet = ({ pageTitle, description, keywords, canonical }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />(<title>{pageTitle}</title>)
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={canonical} />
      </Helmet>
    </>
  );
};
//   
/*
<reference name="head">
<action method="addLinkRel">
<rel>canonical</rel>
<href>https://example.com/preferred-url-here/</href>
</action>
</reference>
*/
export default PageHelmet;