import { Link } from "react-router-dom";
const HeadingImages = ({
  image,
  title,
  heading,
  detail,
  previous,
  next,
  para,
}) => {
  const text = "WebDevelpoment";
  // console.log("text", text.toLowerCase());
  return (
    <>
      <div
        className="container-fluid aboutSecImg "
        style={{
          backgroundImage: `linear-gradient(rgba(0, 5, 5, 0.8), rgba(8, 5, 0, 0.8)),
        url("${image}")`,
        }}
      >
        {/* new code ... */}
        <div
          className=" col-12 card  contactSec text-center justify-content-center "
          style={{ background: "none" }}
        >
          <div className="card-header text-white bold s-30">
            <h6> {heading}</h6>
            <p className="card-text text-warning">{detail}</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default HeadingImages;
