import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ImMenu } from "react-icons/im";
import { AiOutlineMenu } from "react-icons/ai";
import "../../css/style.css";

export default class Header extends Component {
  constructor() {
    super();
    this.state = {
      styleClass: "navbarStyle",
      currRoute: window.location.href.split("/")[3],
      scrollDirection: "up", // Set an initial scroll direction'
      // myElementRef: React.createRef(), // Create a ref object
      number: parseInt(localStorage.getItem("randomNumber")) || 123,
    };
    console.log(this.state.currRoute);
  }

  // .......................................

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    // const width = this.myElementRef.current.offsetWidth; // Access the width using offsetWidth
    // console.log("Width:", width);
    let min = 150;
    let max = 250;
    this.interval = setInterval(() => {
      const newNumber = Math.floor(Math.random() * (max - min + 1) + min);
      this.setState({ number: newNumber });
      localStorage.setItem("randomNumber", newNumber);
    }, 3600000);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.interval);
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos > this.prevScrollPos) {
      this.setState({ scrollDirection: "down" });
    } else {
      this.setState({ scrollDirection: "up" });
    }
    this.prevScrollPos = currentScrollPos;
  };

  // ......................const randomno-..............

  render() {
    return (
      <>
        {/* navbar */}
        <nav
          className={`navbar navbar-expand-md sticky-top nav-shadow ${
            this.state.scrollDirection === "down" ? "hide4" : "show4"
          }`}
          style={{ height: "70px", backgroundColor: "#e5e5e5" }}
        >
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/">
              <img
                src="/assets/image/logoHM.png"
                alt="logo"
                title="H&M Innovance LLP"
                style={{ height: "50px", width: "80px" }}
              />
            </NavLink>
            {/* <div> */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              border="none"
            >
              <AiOutlineMenu />
            </button>
            {/* <h5 className="visitor">Total Visitors:-{this.state.number}</h5> */}
            <div
              className="collapse navbar-collapse ms-2 justify-content-end"
              id="navbarNavDropdown"
              style={{
                width: "100% !important",
                margin: "0 !important",
              }}
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink
                    className={`active text-dark nav-link ms-2 colorEffect  ${
                      (this.state.currRoute == "home" ||
                        this.state.currRoute == "") &&
                      this.state.styleClass
                    }`}
                    aria-current="page"
                    to="/home"
                    onClick={this.showNavBar}
                    style={{
                      border: "none",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      wordSpacing: 1,
                      letterSpacing: ".1px",
                    }}
                  >
                    HOME
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`active text-dark nav-link ms-2 colorEffect  ${
                      this.state.currRoute == "about" && this.state.styleClass
                    }`}
                    to="/about"
                    style={{
                      border: "none",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                  >
                    ABOUT
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className={`active text-dark nav-link ms-2 colorEffect  ${
                      (this.state.currRoute == "web-designing" ||
                        this.state.currRoute == "app-development" ||
                        this.state.currRoute == "software-development" ||
                        this.state.currRoute == "digital-marketing" ||
                        this.state.currRoute == "automated-system" ||
                        this.state.currRoute == "business-analysis" ||
                        this.state.currRoute == "socialMedia-marketing" ||
                        this.state.currRoute == "web-development") &&
                      this.state.styleClass
                    }`}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      border: "none",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                  >
                    SERVICE
                  </NavLink>
                  <div>
                    <ul
                      className="dropdown-menu  text-dark fs-6"
                      style={{ backgroundColor: "#e5e5e5" }}
                    >
                      <li>
                        <NavLink
                          style={{ fontWeight: "500" }}
                          className={`dropdown-item colorEffect  ${
                            this.state.currRoute == "web-designing" &&
                            this.state.styleClass
                          }`}
                          to="/web-designing"
                        >
                          Web Designing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          style={{ fontWeight: "500" }}
                          className="dropdown-item"
                          to="/web-development"
                        >
                          Web Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          style={{ fontWeight: "500" }}
                          className="dropdown-item"
                          to="/app-development"
                        >
                          App Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          style={{ fontWeight: "500" }}
                          className="dropdown-item"
                          to="/software-development"
                        >
                          Software Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          style={{ fontWeight: "500" }}
                          className="dropdown-item"
                          to="/digital-marketing"
                        >
                          Digital Marketing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          style={{ fontWeight: "500" }}
                          className="dropdown-item"
                          to="/automated-system"
                        >
                          Automated System
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          style={{ fontWeight: "500" }}
                          className="dropdown-item"
                          to="/business-analysis"
                        >
                          Business Analysis
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          style={{ fontWeight: "500" }}
                          className="dropdown-item"
                          to="/socialMedia-marketing"
                        >
                          Social Media Marketing
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`active text-dark nav-link ms-2 colorEffect  ${
                      this.state.currRoute == "our-team" &&
                      this.state.styleClass
                    }`}
                    aria-current="page"
                    to="/our-team"
                    style={{
                      border: "none",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                  >
                    OUR TEAM
                  </NavLink>
                </li>
                {/* <li className="nav-item dropdown">
                  <NavLink
                    // to="/product/crm"
                    className={`active text-dark nav-link ms-2 colorEffect  ${
                      this.state.currRoute == "products" &&
                      this.state.styleClass
                    }`}
                    style={{
                      border: "none",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                      // textShadow: "1px 1px #d3d0d0",
                    }}
                    role="button"
                    to="/products"
                  >
                    OUR PRODUCT
                  </NavLink>
                </li> 

                <li className="nav-item">
                  <NavLink
                    className={`active text-dark nav-link ms-2 colorEffect  ${
                      this.state.currRoute == "blog" && this.state.styleClass
                    }`}
                    aria-current="page"
                    to="/blog"
                    style={{
                      border: "none",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                      // textShadow: "2px 2px #d3d0d0",
                    }}
                  >
                    BLOG
                  </NavLink>
                </li>*/}
                <li className="nav-item">
                  <NavLink
                    className={`active text-dark nav-link ms-2 colorEffect  ${
                      this.state.currRoute == "contactUs" &&
                      this.state.styleClass
                    }`}
                    aria-current="page"
                    to="/contactUs"
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    CONTACT US
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div style={{ height: "10vh" }}> </div>
      </>
    );
  }
}
